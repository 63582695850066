











import { computed } from '@vue/composition-api';

export default {
  components: {
    Login: () => import('@/views/Login.vue')
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const loginDialog = computed({
      get: () => props.isLogin,
      set: newVal => {
        ctx.emit('input');
      }
    });

    return { loginDialog };
  }
};
